import {
    PerthNowNavItem,
    PerthNowSection,
    TimestampType,
    TopicPageType,
} from '@news-mono/component-library'
import {
    AdTargeting,
    ContentDataTypes,
    GetRouteAdTargeting,
    NavItem,
    PageType,
    TogglesReduxState,
    Topic,
} from '@news-mono/web-common'
import { layout } from '../../App.routing'
import { getGalleryTopicPageRouteInfo } from './get-gallery-publication-kind-route-info'
import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import {
    navigationNewTaxonomyNGN,
    navigationOriginalTaxonomyNGN,
} from '../../BasePerthNow/BaseComponent/Navigation/navigation-items'
import {
    getPerthNowSectionNav,
    getPerthNowSubTopicNavItems,
} from '../common/ngn-topic-navigation'
import { billboardLeaderboardMrec } from '../ad-units'
import { colorsPN } from '@news-mono/design-tokens'

export interface GetTopicPageRouteInfoProps {
    section: PerthNowSection
    // Main topic to display / use as route info or titles
    topic: Topic
    getAdTargeting: GetRouteAdTargeting
    // Override for ad targetting
    adTopic?: Topic
    // Curation to display in top section (5 card display)
    curation?: string
    // Hides main heading
    hideHeading?: boolean
    // Prevents default templating for title template on route meta
    noMetaTitleTemplate?: boolean
    publicationKind?: 'article' | 'gallery'
    // Controls display of timestamp on cards including position / style
    timestamp?: TimestampType
    toggles?: TogglesReduxState
    // Array of topics for listing display
    topics?: string[]
    topicSource?: string
}

// map topicId to curation id if there's one
function getTopicCuration(topicId: string) {
    switch (topicId) {
        case 'news/wa':
        case 'wa':
            return { curation: 'wa-news', trending: 'wa-news' }
        case 'entertainment':
            return { curation: 'entertainment', trending: 'entertainment' }
        case 'news':
            return { curation: 'news', trending: 'news' }
        case 'food':
        case 'lifestyle/food':
            return { curation: 'bites-and-booze', trending: 'food' }
        case 'lifestyle':
            return { curation: 'lifestyle', trending: 'lifestyle' }
        case 'sport':
            return { curation: 'sport', trending: 'sport' }
        case 'shop-now':
            return { curation: 'shop-now', trending: 'shopnow' }
        default:
            return { curation: undefined, trending: 'top-stories' }
    }
}

const getTrendingCuration = (topic: Topic) => {
    let trendingTopic = topic
    const trendingCuration = getTopicCuration(topic.id).trending

    // If topic isn't a home page / primary topic use parent topic
    if (trendingCuration === 'top-stories') {
        // Avoid circular dependencies
        const MAX_TOPIC_DEPTH = 3
        let i = 0
        while (
            trendingTopic.parent &&
            trendingTopic !== trendingTopic.parent &&
            i < MAX_TOPIC_DEPTH
        ) {
            trendingTopic = trendingTopic.parent
            i++
        }
    }

    return getTopicCuration(trendingTopic.id)?.trending
}

export const getNGNTopicPageRouteInfo = ({
    section,
    topic,
    getAdTargeting,
    adTopic,
    toggles,
    hideHeading,
    noMetaTitleTemplate,
    publicationKind = 'article',
    timestamp,
    topics,
    topicSource,
}: GetTopicPageRouteInfoProps): PageType<PerthNowSection> => {
    const adTargeting: AdTargeting = getAdTargeting(
        'home',
        section,
        adTopic ?? topic,
    )

    const topicListing: string[] = topics ? [topic.id, ...topics] : [topic.id]

    if (publicationKind === 'gallery') {
        return getGalleryTopicPageRouteInfo({
            topic,
            adTargeting,
            section: section || 'default',
        })
    }

    const fiveCardCuration = getTopicCuration(topic.id)?.curation

    // Count in the 5-card layout if it uses top 5 topic listings
    const topicListingOffset = fiveCardCuration ? 0 : 5

    const fiveCardDataArgs: ContentDataTypes = fiveCardCuration
        ? {
              type: 'curation',
              name: fiveCardCuration,
              offset: 0,
              pageSize: 5,
          }
        : {
              type: 'listing',
              topics: [topic.id],
              includeSubTopics: true,
              publicationKind,
              source: topicSource,
              paging: {
                  page: 1,
                  pageSize: 5,
              },
          }
    const useNewTaxonomy =
        toggles &&
        isFeatureEnabled(toFeatureState(toggles), 'perthnow-ngn-new-taxonomy')

    const navigation = useNewTaxonomy
        ? navigationNewTaxonomyNGN
        : navigationOriginalTaxonomyNGN

    const topicHeader = getHeaderNav(section, topic, navigation)
    const navLinks = topicHeader ? getNavLinks(topicHeader.navItem) : []
    const heading =
        topicHeader?.type === 'primary'
            ? topicHeader?.navItem.name
            : topic.title

    return {
        kind: 'page',
        heading: topic.title,
        hideHeading: true,
        pageType: 'topic',
        includeFromSectionInMetaDescription: !topic.seoDescription,
        noMetaTitleTemplate: noMetaTitleTemplate,
        section: section || 'default',
        pageMeta: {
            title: topic.title || topic.seoTitle || '',
            description:
                topic.seoDescription ||
                `The Latest in ${topic.title || topic.seoTitle}`,
        },
        socialMeta: {
            title: topic.seoTitle || '',
            description: topic.seoDescription || '',
        },
        adTargeting,
        compositions: [
            layout.composition({
                type: 'box',
                props: {},
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'pn-section-header',
                            props: {
                                heading,
                                navLinks,
                                mainSection: true,
                                topicHeaderType: topicHeader?.type,
                                accordionProps: {
                                    topics: { primary: topic, secondary: [] },
                                    perthNowSectionNavItems:
                                        getPerthNowSectionNav(),
                                    subTopicNavItems:
                                        getPerthNowSubTopicNavItems(),
                                    parentTopicId: topicHeader?.parentTopicId,
                                },
                            },
                        }),
                        layout.component({
                            type: 'pn-stella',
                            props: {
                                disableImageLazyLoad: true,
                                showAuthor: true,
                                largeCard: {
                                    largeCardHasBackground: false,
                                    largeCardPosition: 'right',
                                    largeCardSpan: 2,
                                },
                                teaserMode: 'visible',
                                headerSectionTag: 'h2',
                                dataDefinitionArgs: fiveCardDataArgs,
                            },
                        }),
                        ...billboardLeaderboardMrec('one', 'topic', 0),
                    ],
                },
            }),
            layout.composition({
                type: 'pn-hades',
                props: {
                    layout: 'topic',
                },
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'pn-topic-card-list',
                            props: {
                                removeHorizontalGutters: true,
                                hideByline: false,
                                topicLevel: 'parent',
                                dataDefinitionArgs: {
                                    type: 'listing',
                                    topics: [topic.id],
                                    publicationKind: 'article',
                                    paging: {
                                        page: 1,
                                        pageSize: 20,
                                        maxPageNumber: 10,
                                        pageOffset: topicListingOffset,
                                    },
                                },
                                cardSpacing: 20,
                                fontScale: 'M',
                                teaserFontScale: 'L',
                                hideCardFooter: false,
                                showCardTopic: true,
                                teaserMode: 'visible',
                                horizontalDividerColor: colorsPN.stroke.weak,
                                timeStamped: true,
                                showAuthor: true,
                                headerFontOverride: {
                                    mobile: 'XS',
                                    tablet: 'XS',
                                    desktop: 'M',
                                },
                                imageSizeOverride: {
                                    mobile: '2XS',
                                    tablet: '2XS',
                                    desktop: 'M',
                                },
                                adUnits: 4,
                            },
                        }),
                    ],
                    sidebar: [
                        layout.component({
                            type: 'ad-unit',
                            props: {
                                noticePosition: 'below-center',
                                padding: [0, 0, 13, 0],
                                slot: {
                                    id: 'sidebar-top',
                                    size: 'desktopMrecHalfPage',
                                },
                                adType: 'inline',
                            },
                        }),
                        layout.component({
                            type: 'romeo',
                            props: {
                                removeHorizontalGutters: true,
                                hideByline: false,
                                topicLevel: 'parent',
                                sectionHeader: {
                                    heading: 'Trending',
                                    hasHeaderLogo: true,
                                },
                                dataDefinitionArgs: {
                                    type: 'curation',
                                    name: `trending-${getTrendingCuration(
                                        topic,
                                    )}`,
                                    offset: 0,
                                    pageSize: 5,
                                },
                                cardSpacing: 20,
                                fontScale: 'XS',
                                hideCardFooter: true,
                                showCardTopic: true,
                                headerSectionTag: 'h4',
                                centreOnTablet: true,
                                imageSizeOverride: {
                                    mobile: '2XS',
                                    tablet: '2XS',
                                    desktop: '2XS',
                                },
                                headerFontOverride: {
                                    mobile: 'XS',
                                    tablet: 'XS',
                                    desktop: 'XS',
                                },
                            },
                        }),
                        layout.component({
                            type: 'pn-newsletter-banner-ngn',
                            props: {
                                marginBottom: 32,
                                marginTop: 32,
                            },
                        }),
                        layout.component({
                            type: 'ad-unit',
                            props: {
                                noticePosition: 'below-center',
                                padding: [13, 0],
                                stickyOffset: 80,

                                slot: {
                                    id: 'sidebar-middle',
                                    size: 'desktopMrec',
                                },
                                adType: 'inline',
                            },
                        }),
                    ],
                },
            }),
            layout.composition({
                type: 'box',
                props: {},
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'taboola',
                            props: {
                                taboolaContainerId:
                                    'taboola-below-category-thumbnails',
                                locationType: 'category',
                            },
                        }),
                    ],
                },
            }),
        ],
    }
}

function getHeaderNav(
    section: PerthNowSection,
    topic: Topic,
    navigation: PerthNowNavItem[],
):
    | { type: TopicPageType; navItem: NavItem; parentTopicId?: string }
    | undefined {
    let type: TopicPageType = 'secondary'
    let parentTopicId: string | undefined
    const primaryNav = navigation.find(
        (nav) =>
            /**
             * @ Todo: refine this logic when NGN new taxonomy is implemented
             * Ideally we should be able to match the primary topic id by `section`
             */
            nav.link.replace(/^\/+/, '') === section ||
            nav.link.replace(/^\/+/, '') === topic.id,
    )

    if (!primaryNav) {
        // to match legacy topic for some edge cases while new taxonomy is not implemented
        const legacyTopic = navigation.find((nav) =>
            nav.subNavLinks?.find(
                (subNav) =>
                    subNav.link.includes(topic.id) ||
                    subNav.link.includes(section),
            ),
        )
        if (legacyTopic) {
            return { type, navItem: legacyTopic, parentTopicId }
        }
        return undefined
    }

    if (primaryNav.link.replace(/^\/+/, '') === topic.id) {
        return { type: 'primary', navItem: primaryNav }
    }

    // find secondary or tertiary topic
    const nav = primaryNav.subNavLinks?.find((subNav) => {
        if (subNav.link.replace(/^\/+/, '') === topic.id) {
            type = 'secondary'
            parentTopicId = primaryNav.link.replace(/^\/+/, '')
            return true
        }
        // find tertiary topic
        const tertiaryNav = subNav.subNavLinks?.find((tertiaryNav) => {
            return tertiaryNav.link.replace(/^\/+/, '') === topic.id
        })

        if (tertiaryNav) {
            parentTopicId = subNav.link.replace(/^\/+/, '')
            type = 'tertiary'
            return true
        }
    })

    if (!nav) {
        return { type, navItem: primaryNav }
    }
    const navItem = (type as TopicPageType) === 'tertiary' ? nav : primaryNav

    return { type, navItem, parentTopicId }
}

function getNavLinks(navItem: NavItem) {
    if (!navItem.subNavLinks) {
        return []
    }
    return navItem.subNavLinks.map((nav) => ({
        link: nav.link,
        text: nav.name,
    }))
}
